(function () {
  const $jQ = jQuery;
  const sh = window.location.hash

  function removeHash () {
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
      history.pushState("", document.title, loc.pathname + loc.search);
    else {
      // Prevent scrolling by storing the page's current scroll offset
      scrollV = document.body.scrollTop;
      scrollH = document.body.scrollLeft;

      loc.hash = "";

      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  }
  removeHash()

  function repositionStickyTabMenu() {
    const box = document.getElementById("layout-header").getBoundingClientRect();
    const tabOffsetTop = box.top + box.height;
    document.getElementById("ac26t-tabmenu").style.top = tabOffsetTop + "px";
  }

  function scrollPageTo(v, s = 500, offset = -32) {
    let top_fixed_menu_offset =
      $jQ("#layout-header").outerHeight() + $jQ("#ac26t-tabmenu").outerHeight();

    $jQ("html, body").animate(
      {
        scrollTop: v - top_fixed_menu_offset + offset,
      },
      s
    );
  }

  function onAC26PageLoad() {
    const tabType = $jQ("#ac26t-tabmenu").data("tabtype");

    if ("ResizeObserver" in window) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.target.id === "layout-header") {
            repositionStickyTabMenu();
          }
        }
      });
      resizeObserver.observe(document.querySelector("#layout-header"));
    } else {
      repositionStickyTabMenu();
      addEventListener("scroll", repositionStickyTabMenu);
      addEventListener("resize", repositionStickyTabMenu);
    }

    if (tabType === "scroll")
      [].forEach.call(
        document.querySelectorAll(".ac26t-tab-link"),
        function (element) {
          element.addEventListener("click", function (event) {
            if (this.hash !== "") {
              event.preventDefault();
              scrollPageTo($jQ(this.hash).offset().top);
              window.history.pushState({ hash: this.hash }, "", this.hash);
              return false;
            }
          });
        }
      );

    if ("IntersectionObserver" in window) {
      const scrollObserver = new IntersectionObserver(
        (entries) => {
          const links = document.querySelectorAll(".ac26t-tab-link");
          entries.map((entry) => {
            if (entry.isIntersecting === true) {
              links.forEach((link) => link.classList.remove("active"));
              document
                .querySelector(`a[href="#${entry.target.id}"]`)
                ?.classList.add("active");
            }
          });
        },
        { rootMargin: "-50% 0% -50% 0%" }
      );
      document
        .querySelectorAll(".ac26t_collapsible")
        .forEach((section) => scrollObserver.observe(section));
    } else {
      var collapsibles = $jQ(".ac26t_collapsible");

      collapsibles = collapsibles.sort(function (a, b) {
        return b.offsetTop - a.offsetTop;
      });

      function handleActive() {
        let headerOffset =
          $jQ("#layout-header").outerHeight() +
          $jQ("#ac26t-tabmenu").outerHeight();
        for (let collapsible of collapsibles) {
          if (window.scrollY >= collapsible.offsetTop - headerOffset) {
            $jQ(".ac26t-tab-link").removeClass("active");
            $jQ("#ac26t-tab-link-" + collapsible.id).addClass("active");
            break;
          }
        }
      }

      addEventListener("scroll", handleActive);
      addEventListener("resize", handleActive);
    }

    // if (window.location.hash) {
    //   scrollPageTo(
    //     document.querySelector(window.location.hash).offsetTop ||
    //       document.querySelector(window.location.hash).offsetParent.offsetTop,
    //     0
    //   );
    // } else
    if (tabType === "collapse")
      $jQ("a[data-toggle='collapse']").click(function (e) {
        const elements = $jQ(this.dataset.target);
        if (elements && elements.length > 0) {
          const element = elements[0];
          if (
            element.classList.contains("show") ||
            element.classList.contains("collapsing")
          ) {
            scrollPageTo($jQ(element).offset().top, 250);
            e.stopPropagation();
            return false;
          }

          $jQ("a[data-toggle='collapse']").removeClass("active");
          this.classList.add("active");

          $jQ(`.ac26t_collapsible:not(${this.dataset.target})`).collapse(
            "hide"
          );

          $jQ(element).on("shown.bs.collapse", function () {
            scrollPageTo($jQ(element).offset().top, 250);
          });

          window.history.pushState(
            { hash: this.dataset.target },
            "",
            this.dataset.target
          );
        }
      });
    else if (tabType === "fade") {
      $jQ("a[data-toggle='tab']").click(function (e) {
        // display none element doesn't have offset, so we use the previously visible element
        scrollPageTo(document.querySelector(".tab-content .active")?.offsetTop);
        window.history.pushState(
          { hash: this.dataset.target },
          "",
          this.dataset.target
        );
      });
    }

    window.onpopstate = (ev) => {
      const h =
        ev.state == null
          ? $jQ(`.ac26t-tab-link`)[0].dataset.target
          : ev.state.hash;
      $jQ(`.ac26t-tab-link[data-target="${h}"]`).tab("show");
    };

    if (sh) {
      $jQ(`.ac26t-tab-link[data-target="${sh}"]`).tab("show");
      if (tabType === 'scroll')
        scrollPageTo(document.querySelector(`${sh}`)?.offsetTop)
      window.location.hash = sh
    }
  }

  $jQ(document).ready(onAC26PageLoad);
})();
